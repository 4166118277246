.loader-page {
  height: 100vh;
  width: 100%;
  background-color: var(--Cetacean-Blue);
  margin-top: 0;
  position: fixed;
}
.loader-component {
  width: 30vw;
  height: 30vh;
  margin-top: 33vh;
}
