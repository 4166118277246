.about {
  overflow: hidden;
  background: var(--Baby-Powder);
}
.about text {
  color: var(--Turquoise);
}
.about-hero {
  background: var(--Cetacean-Blue);
}
.about-hero h1 {
  color: var(--White);
}
.about-hero h4 {
  color: var(--White);
}
.about-hero p {
  color: var(--Light-Silver);
}
.about-image {
  object-fit: cover;
}
.about-image img {
  width: 100%;
  height: 100%;
}
.gradient-div {
  background: linear-gradient(
    to bottom,
    var(--Cetacean-Blue) 50%,
    var(--Baby-Powder) 50%
  );
  margin-top: -1%;
}
.card-about {
  box-shadow: 0px 8px 18px 4px rgba(26, 26, 26, 0.16);
  border-radius: 8px;
  background: var(--White);
}
.h-30vh {
  height: 35vh;
}
.card-about h3 {
  color: var(--Eerie-Black);
}
.card-about h5 {
  color: var(--Jet-Black);
}
.Talk-to-us-about {
  background: var(--Teal-Green);
  border-radius: 6px;
  color: var(--White);
  border: 2px solid var(--White);
}
.Talk-to-us-about h5 {
  color: var(--White);
}
.Talk-to-us-about:hover {
  background: var(--highreadability);
  border-radius: 6px;
  color: var(--White);
  border: 2px solid var(--highreadability);
}
.Talk-to-us-about:focus:active {
  background: var(--Baby-Powder);
  color: var(--highreadability);
  border: 2px solid var(--highreadability);
  border-radius: 6px;
}
.Talk-to-us-about:focus:active h5 {
  color: var(--highreadability);
}
.our-values h5 {
  color: var(--Jet-Black);
}
.our-values p {
  color: var(--Granite-Gray);
}
.our-values-icon {
  height: 12vh;
  width: 12vh;
  object-fit: cover;
}
.our-values-icon img {
  height: 100%;
  width: 100%;
}
.about span {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .about-image img {
    transform: scale(0.8);
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .h-30vh {
    height: 35vh;
  }
}
@media (min-width: 992px) and (max-width: 1920px) {
  .about span {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .about span {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
@media only screen and (max-width: 576px) {
  .about span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
