.download-button {
  background: var(--Teal-Green);
  padding: 16px 24px;
  border-radius: 8px;
  white-space: normal;
  background: var(--Teal-Green);
  color: var(--White);
}
/*.downloadbutton i {
  background: transparent;
  color: var(--Baby-Powder);
}*/
.download-button img {
}
.download-button:hover {
  background: var(--Website-gradient);
}
.download-button:focus:active {
  color: var(--White);
  background: var(--highreadability);
}
.download-button:focus:active i {
  color: var(--highreadability);
}

button {
  outline: none;
  box-shadow: none;
  border: none;
}
button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
button:focus:active {
  outline: none;
  box-shadow: none;
  border: none;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .icon-eye {
    color: var(--Baby-Powder);
    width: 4vw;
    height: 4vh;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .download-button {
    padding: 16px 24px;
    width: 270px;

    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .download-button {
    padding: 12px 20px;
    width: 240px;
    align-items: center;
  }
}
@media only screen and (max-width: 576px) {
  .download-button {
    padding: 8px;
    width: 195px;
    align-items: center;
  }
}
