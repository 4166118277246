.contact-form label {
  color: var(--Granite-Gray);
}
.contact-title p {
  color: var(--Granite-Gray);
}
.border-full {
  border: 1px solid var(--Light-Silver);
  border-radius: 8px;
}
.no-styling {
  border: none;
}
input:focus,
textarea:focus {
  outline: none;
}
.input-padding {
  padding: 10px;
}
.label-inside {
  position: relative;
}
.label-inside::placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--Granite-Gray);
}
.contactus .send {
  width: 100%;
  background: var(--Teal-Green);
  border-radius: 8px;
  color: var(--White);
}
input:hover::placeholder,
textarea:hover::placeholder {
  color: var(--purple);
}
.border-bottom-1 {
  border-bottom: 1px solid var(--Light-Silver);
}
input:hover + .border-bottom-1 {
  border-bottom: 1px solid rgb(39, 34, 100);
}
.error-message {
  color: var(--Error-Red);
}
.contact-form textarea {
  min-height: 25vh;
}
@media (min-width: 1920px) {
  .contact-form textarea {
    min-height: 18vh;
  }
}
@media (max-width: 576px) {
  .contact-form textarea {
    min-height: 18vh;
  }
}
@media (max-width: 992px) {
  .contact-form textarea {
    min-height: 18vh;
  }
}
