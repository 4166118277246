.default .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  background-color: var(--Baby-Powder);
  box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
}
.default-nav a {
  text-decoration: none;
  color: var(--Eerie-Black);
}
.default-nav a:hover {
  text-decoration: none;
  color: var(--Eerie-Black);
}
.default .active {
  border-bottom: 2px solid var(--Granite-Gray);
}

.showmefortablet-lg {
  display: none;
}
.toggler-icon {
  width: 24px;
  height: 2px;
  background: var(--Teal-Green);
  border-radius: 2px;
  display: block;
  transition: all 0.2s;
}
.middle-bar {
  margin: 5px auto;
}
.top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 10%;
}
.collapsed .top-bar {
  transform: rotate(0);
}
.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
.collapsed .bottom-bar {
  transform: rotate(0);
}
.navbar-toggler .collapsed .toggler-icon {
  background-color: var(--White);
}
.mobile-number {
  display: none;
}
.navbar button {
  outline: none;
  box-shadow: none;
  border: none;
}
.navbar button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.navbar button:focus:active {
  outline: none;
  box-shadow: none;
  border: none;
}
.navbar-links-row {
  display: flex;
  flex-direction: row;
}
.hidemefortablet-lg {
  display: block;
}
.call-download-mobile-default .callbutton {
  color: var(--Jet-Black);
}
@media (min-width: 768px) and (max-width: 991px) {
  .showmefortablet-lg {
    display: block;
  }
  .hidemefortablet-lg {
    display: none;
  }
  .navbar-collapse .nav-item:nth-child(4) {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .navbar-links-row,
  .call-download-mobile-default {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .navbar-collapse {
    background: var(--Baby-Powder);
  }
  .nav-item {
    justify-content: center;
    display: flex;
  }
  .navbar-collapse .nav-item {
    margin-top: 24px;
  }
  .call-download-mobile-default .nav-item {
    padding: 0px 0 8px 0;
  }
  .call-download-mobile-default .nav-item:nth-child(1) {
    padding: 18px 0 0;
  }
  .navbar-collapse .download-button-nav {
    margin-bottom: 10px;
  }
}
