.sitemap-container {
  text-align: start;
  justify-content: center;
  width: 30%;
}
.sitemap {
  width: 100%;
  min-height: 55vh;
  position: relative;
}
.sitemap-list {
  display: flex;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
}
.sitemap-item {
  flex-basis: 50%;
  list-style-type: none;
  margin: 10px 0 10px 0;
  text-decoration: underline;
}
.sitemap-link {
  color: #084a44;
  text-decoration: none;
  font-weight: semibold;
}

.sitemap-link:hover {
  color: #084a44;
}
@media (min-width: 576px) and (max-width: 992px) {
  .sitemap-container {
    text-align: start;
    justify-content: center;
    width: 50%;
  }
  .sitemap-item {
    flex-basis: 40%;

    margin: 10px 0 10px 0;
  }
  .sitemap-list {
    display: flex;

    align-items: self-start;
  }
  .sitemap {
    width: 100%;
    min-height: 45vh;
    position: relative;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .sitemap-item {
    flex-basis: 50%;

    margin: 8px 0 8px 0;
  }
  .sitemap-list {
    display: flex;

    align-items: self-start;
  }
  .sitemap-container {
    text-align: start;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .sitemap {
    width: 100%;
    min-height: 25vh;
    position: relative;
  }
}
@media (min-width: 1920px) {
  .sitemap {
    width: 100%;
    min-height: 55vh;
    position: relative;
  }
}
