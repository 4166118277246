.banner {
  background: var(--Azureish-White);
  max-width: 100%;
  overflow: hidden;
}
.banner img {
  object-fit: cover;
}
.banner h3 {
  color: var(--Jet-Black);
}
.banner text {
  color: var(--Teal-Green);
}
.banner .banner-content {
  z-index: 9;
  position: relative;
}
.banner img {
  z-index: 3;
  position: relative;
}
.right-image img {
  margin-left: -12vw;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .banner img {
    transform: scale(0.8);
  }
  .right-image img {
    margin-left: -12vw;
  }
  .left-image img {
    margin-left: -10vw;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .banner img {
    transform: scale(0.8);
  }
  .right-image img {
    margin-left: -12vw;
  }
  .left-image img {
    margin-left: -14vw;
  }
}
@media only screen and (max-width: 992px) {
  .banner img {
    display: none;
  }
}
