.transparent-nav .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border: none;
  box-shadow: none;
}

.transparent-nav .nav-item-trans {
  color: var(--White);
  text-decoration: none;
}
.transparent-nav .navbar a:hover {
  text-decoration: none;
  color: var(--White);
}
.transparent-nav .active {
  border-bottom: 2px solid var(--White);
}

.transparent-nav .showmefortablet-lg {
  display: none;
}
.transparent-nav .toggler-icon {
  width: 24px;
  height: 2px;
  background: var(--White);
  border-radius: 2px;
  display: block;
  transition: all 0.2s;
}
.transparent-nav .middle-bar {
  margin: 5px auto;
}
.transparent-nav .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.transparent-nav .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.transparent-nav .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 10%;
}
.transparent-nav .collapsed .top-bar {
  transform: rotate(0);
}
.transparent-nav .collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
.transparent-nav .collapsed .bottom-bar {
  transform: rotate(0);
}
.transparent-nav .navbar-toggler .collapsed .toggler-icon {
  background-color: var(--White);
}

.transparent-nav .navbar button {
  outline: none;
  box-shadow: none;
  border: none;
}
.transparent-nav .navbar button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.transparent-nav .navbar button:focus:active {
  outline: none;
  box-shadow: none;
  border: none;
}
.transparent-nav .navbar-links-row-trans {
  display: flex;
  flex-direction: row;
}
.transparent-nav .hidemefortablet-lg {
  display: block;
}
.transparent-nav .call-download-mobile .callbutton {
  color: var(--White);
}
.transparent-nav .showmefortablet-lg .callbutton {
  color: var(--White);
}
.navbar-links-row-trans {
  color: var(--White);
}
@media (min-width: 768px) and (max-width: 991px) {
  .transparent-nav .showmefortablet-lg {
    display: block;
  }
  .transparent-nav .hidemefortablet-lg {
    display: none;
  }
  .transparent-nav .navbar-collapse .nav-item-trans:nth-child(4) {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .transparent-nav .navbar-links-row-trans,
  .transparent-nav .call-download-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .transparent-nav .navbar-collapse {
    background: var(--Baby-Powder);
    margin-top: 25px;
    border-radius: 25px;
  }
  .transparent-nav .nav-item-trans {
    justify-content: center;
    display: flex;
  }
  .transparent-nav .nav-item-trans {
    text-decoration: none;
    color: var(--Eerie-Black);
  }
  .transparent-nav .active {
    border-bottom: 2px solid var(--Granite-Gray);
  }

  .transparent-nav .navbar-collapse .nav-item-trans {
    margin-top: 18px;
    margin-bottom: 12px;
  }
  .transparent-nav .call-download-mobile .callbutton {
    color: var(--Jet-Black);
  }

  .transparent-nav .call-download-mobile .nav-item-trans:nth-child(1) {
    padding-top: 12px;
  }
  .transparent-nav .call-download-mobile .nav-item-trans:nth-child(2) {
    padding-top: 12px;
  }
}
