@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Playfair+Display:ital,wght@1,600;1,700&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  --highreadability: #084a44;
  --Persian-Green: #119f93;
  --Eerie-Black: #1a1a1a;
  --Jet-Black: #333333;
  --Granite-Gray: #666666;
  --White: #ffffff;
  --Light-Silver: #d7d9d6;
  --Baby-Powder: #fdfffc;
  --Teal-Green: #0e857b;
  --Azureish-White: #d7f0eb;
  --Violet: #8200ff;
  --purple: #42047e;
  --Turquoise: #16ccbd;
  --Cetacean-Blue: #0d0a2c;
  --Error-Red: #d96468;
  --Website-gradient: linear-gradient(
      72.6deg,
      rgba(22, 204, 189, 0) 0%,
      #16ccbd 100.71%
    ),
    linear-gradient(107.56deg, #8200ff 0%, rgba(130, 0, 255, 0) 100%), #0d0a2c;
  --hero-bg-gradient: linear-gradient(
      72.6deg,
      rgba(17, 159, 147, 0) 0%,
      #119f93 100.71%
    ),
    linear-gradient(107.56deg, #42047e 0%, rgba(66, 4, 126, 0) 100%), #0d0a2c;

  --button-gradient: linear-gradient(
      72.6deg,
      rgba(22, 204, 189, 0) 0%,
      #16ccbd 100.71%
    ),
    linear-gradient(107.56deg, #8200ff 0%, rgba(130, 0, 255, 0) 100%), #0d0a2c;

  --font-stack-manrope: "Manrope";
  --font-stack-Playfair-Display: "Playfair Display";
}
.box-shadow-banner{
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}
.box-shadow-medium{
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}
.manrope {
  font-family: "Manrope";
}
.playflair {
  font-family: "Playfair Display";
}
.background-baby {
  background: var(--babypowder);
}
.mymanrope {
  font-family: "Manrope";
}
.myplayfair {
  font-family: "Playfair Display";
}
.italic {
  font-style: italic;
}
.container {
  overflow: hidden;
}
.extrabold {
  font-weight: 800;
}
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
.medium {
  font-weight: 500;
}
.regular {
  font-weight: 400;
}
.light {
  font-weight: 300;
}
.extralight {
  font-weight: 200;
}
h1 {
  font-size: 4rem;
  line-height: 5.5rem;
}
h2 {
  font-size: 3rem;
  line-height: 4.5rem;
}
h3 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
h4 {
  font-size: 2rem;
  line-height: 3rem;
}
h5 {
  font-size: 1.5rem;
  line-height: 2rem;
}
h6 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
small {
  font-size: 0.75rem;
  line-height: 1rem;
}
.section-padding {
  padding-top: 5rem;
}
.hidemeforpc {
  display: none;
}
.showmefortablet {
  display: none;
}
.showmeformobile {
  display: none;
}
.showmeforpc {
  display: block;
}
button {
  transition: all 200ms ease-in;
}
span {
  font-size: 1.5rem;
  line-height: 2rem;
}
@media only screen and (max-width: 576px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  h6 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  p {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  small {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }
  span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .hidemeforpc {
    display: block;
  }
  .hidemeformobile {
    display: none;
  }
  .showmefortablet {
    display: none;
  }
  .showmeformobile {
    display: block;
  }
  .showmeforpc {
    display: none;
  }
  .section-padding {
    padding-top: 3rem;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .hidemeforpc {
    display: block;
  }
  .hidemefortablet {
    display: none;
  }
  .hidemeformobile {
    display: block;
  }
  .showmefortablet {
    display: block;
  }
  .showmeforpc {
    display: none;
  }
  h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  small {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  span {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .section-padding {
    padding-top: 4rem;
  }
}

@media (min-width: 992px) and (max-width: 1920px) {
  h1 {
    font-size: 4rem;
    line-height: 5.5rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  h3 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  h4 {
    font-size: 2rem;
    line-height: 3rem;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  small {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  span {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .section-padding {
    padding-top: 5rem;
  }
  .showmefortablet {
    display: none;
  }
}
