@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Playfair+Display:ital,wght@1,600;1,700&display=swap");
.hero-section {
  background: var(--hero-bg-gradient);
  height: 100vh;
  color: var(--white);
  overflow: hidden;
  z-index: 5;
}

.greentext {
  color: var(--Turquoise);
}
.cup-image {
  transform: rotate(-15deg);
  margin-top: 22vh;
}
.cup-image img {
  transform: scale(0.6);
}
.watch-image {
  margin-top: 9vh;
  transform: rotate(11.25deg);
  margin-bottom: 10px;
}
.watch-image img {
  transform: scale(0.4);
}
.hero-section img {
  width: 100%;
  height: 100%;
}
.cap-image {
  transform: rotate(-19.65deg);
  margin-top: 15vh;
}
.cap-image img {
  transform: scale(0.7);
}
.packaging {
  transform: rotate(10.28deg);
}
.packaging img {
  transform: scale(0.8);
}
.tshirt {
  transform: rotate(5deg);
}
.tshirt img {
  transform: scale(1.3, 1);
}
.main-heading {
  color: var(--white);
}
.primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--White);
  border-radius: 8px;
  color: var(--Teal-Green);
  transition: all 200ms ease-in;
}
.primary-btn:hover {
  background: var(--White);
}
.primary-btn:hover h5 {
  background: var(--Website-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.primary-btn:focus:active {
  background: var(--highreadability);
}

.secondary-btn {
  border: 1px solid #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--White);
}
.secondary-btn:hover {
  background: rgba(255, 255, 255, 0.16);
  color: var(--white);
  border: 1px solid #ffffff;
}
.secondary-btn:focus {
  background: transparent;
  color: var(--Light-Silver);
  border: 1px solid var(--Light-Silver);
}
.secondary-btn:active {
  background: transparent;
  color: var(--Light-Silver);
  border: 1px solid var(--Light-Silver);
}
.hero-center-text h1 {
  transform: scale(0.9);
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .background-images-hero {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .background-images-hero {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .background-images-hero {
    display: none;
  }
}

.hero-section {
  color: #ffffff;
}
/*Whats New*/
.WhatsNew {
  overflow: hidden;
  font-family: var(--font-stack-manrope);
}

.showmeonhover {
  visibility: hidden;
}
.slide-up1 {
  padding: 20px 0 20px 0;
}
.slide-up1 .overlay1 {
  background-color: #ffffff;
  color: rgb(4, 4, 4);
  transform: translateY(70%);
  -webkit-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
}

.slide-up1 .box1:hover .overlay1 {
  transform: translateY(0);
}

.overlay2 {
  height: 95%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px -4px 9px rgba(26, 26, 26, 0.16);

  border-radius: 8px;
  background: #ffffff;
  padding: 10px;
  z-index: 9;
}
.slide-up1 .overlay2 {
  background-color: #ffffff;
  color: rgb(4, 4, 4);
  transform: translateY(45%);
  -webkit-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
}
.slide-up1 .box1:hover .overlay2 {
  transform: translateY(0);
}
.slide-up1 .box1:hover i {
  display: none;
}
.slide-up1 .box1 .overlay2 i {
  font-size: 25px;
  color: var(--Jet-Black);
}

.box1 {
  display: block;
  height: 24rem;
  width: 19.12rem;
  overflow: hidden;
  border-radius: 8px;
  text-align: center;
  position: relative;
  background: #f3f2f4;
  -webkit-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  -moz-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
  -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
}
.product-title-mobile {
  padding-top: 12px;
}
.overlay1 h5 {
  color: var(--Jet-Black);
}
.overlay1 p {
  color: var(--Granite-Gray);
}
.overlay1 {
  height: 75%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 8px;
  background: #ffffff;
  padding: 25px;
  z-index: 9;
}
.card-footer {
  width: 100%;
  height: 50%;
  border-radius: 8px;
}
.product-image-whatsnew img {
  transform: scale(1);
}
.slide-up1 .box1:hover .showmeonhover {
  visibility: visible;
}
.slide-up1 .box1:hover .hidemeonhover {
  visibility: hidden;
}
.product-heading {
  background: #ffffff;
}
.product-image-whatsnew img {
  object-fit: cover;
}
.cant-decide-card {
  background: var(--Azureish-White);
  overflow: hidden;
  box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  border-radius: 16px;
  height: 23.75rem;
}
.cant-decide-card .card-text {
  padding-left: 5px;
  z-index: 3;
  position: relative;
}
.chatbot-img img {
  width: 100%;
  height: 100%;
}
.chatbot-img {
  right: 0;
  height: 23.75rem;
}
.cta-button button {
  outline: none;
  border: none;
  background-color: var(--Teal-Green);
  border-radius: 6px;
}
.cta-button button:hover .material-2 {
  background: var(--button-gradient);
}
.cta-button button:focus:active .material-2 {
  background-color: var(--highreadability);
}

.material-2 {
  border-radius: 6px;
  padding: 8px;
  background-color: var(--Teal-Green);
  color: var(--White);
}
.lets-talk-btn {
  background-color: var(--Teal-Green);
  color: var(--White);
}
.lets-talk-btn:hover {
  background: var(--button-gradient);
  color: var(--White);
}
.lets-talk-btn:focus:active {
  background: var(--highreadability);
  color: var(--White);
}
.card-footer p {
  color: var(--Granite-Gray);
}
@media only screen and (width: 768px) {
  .Whats-new-section {
    padding-top: 25rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .bg-whats img {
    transform: scale(0.9);
  }
  .Whats-new-section {
    margin-top: -30%;
  }

  .box1 {
    width: 14.65rem;
    height: 20.75rem;

    -webkit-box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    -moz-box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    border-radius: 6px;
  }
  .cant-decide-card {
    padding: 15px 0 15px 0;
  }
  .bg-whats img {
    transform: scale(0.9);
  }
  .slide-up1 .overlay1 {
    transform: translateY(68%);
  }
  /* .slide-up1 .overlay1 h5 {
    transform: scale(0.9);
  }*/
  .box1 p {
    transform: scale(0.9);
  }
  .box1 .lets-talk-btn {
    margin-top: -1.5vw;
  }
  .overlay1 {
    padding: 20px;
    height: 85%;
  }
  .cant-decide-card {
    height: 20.25rem;
  }
  .chatbot-img {
    height: 20.25rem;
  }
  .cant-decide-card .card-text {
    padding-left: 3px;
  }
  .material-2 {
    padding: 8px;
    border-radius: 6px;
  }
  .product-image-whatsnew img {
    max-width: 100%;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .bg-whats img {
    transform: scale(0.8);
  }
  .Whats-new-section {
    margin-top: -37%;
  }
  .box1 {
    width: 13.65rem;
    height: 20.75rem;

    -webkit-box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    -moz-box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    box-shadow: 0px 2px 7px rgba(26, 26, 26, 0.14);
    border-radius: 6px;
  }
  .cant-decide-card {
    padding: 15px 0 15px 0;
  }

  .slide-up1 .overlay1 {
    transform: translateY(70%);
  }
  /* .slide-up1 .overlay1 h5 {
    transform: scale(0.8);
  }*/
  .box1 p {
    transform: scale(0.8);
  }
  .box1 .lets-talk-btn {
    margin-top: -1.5vw;
  }
  .overlay1 {
    padding: 20px;
    height: 88%;
  }
  .cant-decide-card {
    height: 20.6rem;
  }
  .chatbot-img {
    height: 20.6rem;
  }
  .cant-decide-card .card-text {
    padding-left: 3px;
  }
  .material-2 {
    padding: 6px;
    border-radius: 6px;
  }
  .product-image-whatsnew img {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .bg-whats img {
    transform: scale(0.8);
  }
  .Whats-new-section {
    margin-top: -45%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .bg-whats img {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .box1 {
    width: 13.5rem;
    height: 17rem;

    -webkit-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -moz-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    border-radius: 6px;
  }
  .slide-up1 .overlay2 {
    transform: translateY(59%);
  }
  .overlay2 {
    padding: 8px 20px 0 20px;
    height: 65%;
  }

  .slide-up1 .box1 .overlay2 i {
    font-size: 20px;
  }
  .cant-decide-card {
    height: 17rem;
  }
  .chatbot-img {
    height: 17rem;
  }
  .cant-decide-card .card-text {
    padding-left: 3px;
  }
  .material-2 {
    padding: 8px;
    border-radius: 6px;
  }
  .product-image-whatsnew img {
    max-width: 100%;
  }
  .bg-whats img {
    transform: scale();
  }
}
@media (min-width: 420px) and (max-width: 576px) {
  .box1 {
    width: 10.5rem;
    height: 15rem;

    border-radius: 4px;
    background: #f3f2f4;
    -webkit-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -moz-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  }
  .slide-up1 .overlay2 {
    transform: translateY(53%);
  }
  .overlay2 {
    padding: 8px 20px 0 20px;
    height: 67%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px -4px 9px rgba(26, 26, 26, 0.16);
    border-radius: 8px;
    background: #ffffff;
    padding: 10px;
    z-index: 9;
  }

  .slide-up1 .box1 .overlay2 i {
    font-size: 18px;
  }
  .cant-decide-card {
    height: 11rem;
  }
  .chatbot-img {
    height: 11rem;
  }
  .cant-decide-card .card-text {
    padding-left: 3px;
  }
  .material-2 {
    padding: 6px;
    border-radius: 6px;
  }
  .product-image-whatsnew img {
    max-width: 100%;
  }
  .chatbot-img {
    left: 20vw;
  }
}
@media only screen and (max-width: 420px) {
  .box1 {
    width: 9.25rem;
    height: 12.93rem;
    -webkit-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -moz-box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    -o-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    -ms-filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
    box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
    border-radius: 4px;
    background: #f3f2f4;
  }
  .slide-up1 .overlay2 {
    transform: translateY(60%);
  }

  .overlay2 {
    padding: 0px 10px 0 10px;
    height: 76%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px -4px 9px rgba(26, 26, 26, 0.16);
    border-radius: 8px;
    background: #ffffff;

    z-index: 9;
  }
  .product-title-mobile {
    width: 100%;
  }

  .slide-up1 .box1 .overlay2 i {
    font-size: 15px;
  }
  .cant-decide-card {
    height: 8rem;
  }
  .chatbot-img img {
    height: 8rem;
  }
  .cant-decide-card .card-text {
    padding: 3px;
  }
  .chatbot-img {
    left: 25vw;
  }
  .overlay1 {
    padding: 8px;
    height: 70%;
    filter: drop-shadow(0px 4px 9px rgba(26, 26, 26, 0.16));
  }
  .material-2 {
    padding: 4px;
    border-radius: 4px;
  }

  .product-image-whatsnew img {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1400px) {
  .Whats-new-section {
    margin-top: -25%;
  }
}
@media only screen and (max-width: 320px) {
  .chatbot-img img {
    display: none;
  }
}
/*HowCanHelp*/
.how-can-we-help {
  overflow: hidden;
}
.how-can-we-help title {
  color: var(--Eerie-Black);
}
.how-can-we-help h3 {
  color: var(--Eerie-Black);
}
.services-cards h2 {
  background: linear-gradient(80deg, rgba(22, 204, 189, 0) 0%, #16ccbd 30%),
    linear-gradient(20deg, #8200ff 0%, rgba(130, 0, 255, 0) 50%), #0d0a2c;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.services-cards .desc {
  color: var(--Granite-Gray);
}

.product-card {
  width: 150px;
  height: 50px;
  background: #16ccbd;
}

/*Best Cards*/
.best-coorporate {
  background: var(--Cetacean-Blue);
}
.best-coorporate .container {
  padding: 5rem 0 5rem 0;
}
.best-coorporate .first-head {
  color: var(--White);
  font-family: var(--font-stack-manrope);
}
.playflair-head h2 {
  font-family: var(--font-stack-Playfair-Display);
  color: var(--Turquoise);
}
.best-corporate-cards h5 {
  color: var(--White);
}
.best-corporate-cards p {
  color: var(--Light-Silver);
}
.best-corporate-cards .card-item {
  display: flex;
  justify-content: start;
  padding: 25px 0 5px 0;
}
.best-corporate-cards .card-body {
  width: 15rem;
  height: 24rem;
  padding: 25px 1px 25px 15px;
  border: 2px solid var(--Cetacean-Blue);
  white-space: normal;
}
.best-corporate-cards .card-body:hover .image {
  filter: drop-shadow(0px 4px 9px rgba(22, 204, 189, 0.48));
}
.best-corporate-cards .card-body:hover {
  border: 2px solid var(--Turquoise);
  border-radius: 16px;
}
@media (min-width: 576px) and (max-width: 991px) {
  .best-coorporate .container {
    padding: 4rem 0 4rem 0;
  }
  .best-corporate-cards .card-item {
    padding: 15px 5px 4px 5px;
  }
  .best-corporate-cards .card-body {
    width: 18rem;
    height: 17.5rem;
    padding: 15px 5px 15px 8px;
  }
  .best-corporate-cards .card-body:hover {
    border: 2px solid #16ccbd;
    border-radius: 16px;
  }
}
@media only screen and (max-width: 576px) {
  .best-corporate-cards .card-body .image {
    transform: scale(0.6);
  }
  .best-coorporate .container {
    padding: 3rem 0.5rem 3rem 0.5rem;
  }
  .best-corporate-cards .card-item {
    padding: 12px 3px 2px 3px;
  }
  .best-corporate-cards .card-body {
    width: 21.9rem;
    height: 12.5rem;
    padding: 8px 3px 8px 5px;
  }
  .best-corporate-cards .card-body:hover {
    border: 2px solid #16ccbd;
    border-radius: 16px;
  }
}
/*Qr Code*/
.qr-code {
  background: var(--Persian-Green);
}
.qr-code h2 {
  color: var(--Baby-Powder);
}
.qr-code h5 {
  color: var(--White);
}
.qr-image {
  object-fit: cover;
}
.qr-image img {
  width: 100%;
  height: 100%;
}
text {
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
@media (min-width: 576px) and (max-width: 991px) {
}

@media only screen and (max-width: 576px) {
}
/*Ordering Process*/
.ordering-process h2 {
  color: var(--Eerie-Black);
}
.ordering-process h5 {
  color: var(--Jet-Black);
}
.ordering-process p {
  color: var(--Granite-Gray);
}
.ordering-process .content-section {
  padding: 70px 0 70px 0;
}
.image-section {
  height: 5vh;
}
.content-body:nth-child(5) .dashed-line {
  display: none;
}
.ordering-image {
  object-fit: contain;
}
.dashed-line {
  border: 2px dashed var(--Persian-Green);
  opacity: 0.4;
  width: 80%;
}
