.not-found {
  background: var(--Baby-Powder);
  height: 100vh;
}

.not-found-image {
  width: 100%;
  height: 60vh;
}
.not-found-image img {
  width: 100%;
  height: 100%;
}
.go-back-home {
  background: var(--Teal-Green);
  border-radius: 8px;
  color: var(--White);
  border: 2px solid var(--Baby-Powder);
}
.go-back-home:hover {
  background: var(--Baby-Powder);

  color: var(--highreadability);
  border: 2px solid var(--highreadability);
  border-radius: 8px;
}
.not-found-contact {
  border: 1px solid var(--Teal-Green);
  border-radius: 8px;
  color: var(--Teal-Green);
  border: 1px solid var(--Baby-Powder);
}
.not-found-contact:hover {
  background: rgba(17, 159, 147, 0.16);
  color: var(--Teal-Green);
  border: 1px solid var(--Teal-Green);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .not-found-image {
    width: 100%;
    height: 35vh;
  }
}
