.WaitContainer {
  text-align: center;
  color: #0e857b;
  height: 61.8vh;
}
.WaitImage {
  display: flex;
  width: 20vw;
  height: 40vh;
  flex-basis: 40%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
