.contactus {
  background: var(--Baby-Powder);
  overflow-x: hidden;
}
.contactus-content {
  padding-top: 35px;
}
.contactus text {
  color: var(--Teal-Green);
}
.talk-to-us h5 {
  color: var(--Jet-Black);
}
.contactus .contact-box h6 {
  color: var(--Jet-Black);
}
.contactus .contact-box a:hover h5 {
  color: var(--Violet);
}
.contactus .contact-box a:hover h6 {
  background: var(--Website-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
}

.contact-box {
  background: var(--White);
  overflow-wrap: break-word;
  box-shadow: 0px 4px 9px rgba(26, 26, 26, 0.16);
  border-radius: 8px;
}
.contact-box2 {
  background: var(--White);
  box-shadow: 0px 8px 18px 4px rgba(26, 26, 26, 0.16);
  border-radius: 8px;
  min-height: 70vh;
}
.talk-to-us {
  min-height: 90vh;
}
@media (max-width: 992px) {
  .order2 {
    order: 2;
  }
  .order1 {
    order: 1;
  }
  .talk-to-us {
    padding-top: 5vh;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .talk-to-us p {
    color: var(--Jet-Black);
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .follow-us {
    margin-left: 2vw;
  }

  .contact-box2 {
    box-shadow: 0px 8px 18px 4px rgba(26, 26, 26, 0.16);
    border-radius: 8px;
    min-height: 50vh;
  }
  .talk-to-us {
    min-height: 50vh;
  }
}
@media (max-width: 576px) {
  .contact-box2 {
    min-height: 30vh;
  }
  .talk-to-us {
    min-height: 50vh;
  }
}
@media (min-width: 1920px) {
  .contact-box2 {
    min-height: 50vh;
  }
  .talk-to-us {
    min-height: 50vh;
  }
}
