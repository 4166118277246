.flex-grow-1 {
  flex-grow: 1;
}
 
.banner-image{
  border-radius: 16px;
  object-fit: cover;
}
.category-name {
  color: var(--companygreen);
}
.read-more-btn {
  outline: none;
  border: none;
  color: var(--companygreen);
  background-color: transparent;
}
.related-article {
  background: var(--white);
  border-radius: 16px;
}
.article-images img{
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

 .insta-image img{
  width: 100%;
  height: 100%;
  border-radius: 16px;
 }
.related-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.related-img img{
border-radius: 16px 0 0 16px;
}
.img-cover-article {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;  
}
.mobile-blogs-banner-article {
  position: relative;
  height: 30vh; 
  width: 100%;
}
.related-img-mob{
  border-radius: 16px 16px 0 0;
}
 .bg-BlackTrans{
  background: rgba(26, 26, 26, 0.88);}

  .bottom-overlay {
    bottom: 0;
    height: 25%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 0 0 1rem 1rem;
  }
  .overlay-container {
    position: relative;
  }
  .custom-col {
    padding: 0 1rem;  
  }
  
  .custom-col:first-child {
    padding-left: 0;
    padding-right: 2rem;
  }
  
  .custom-col:last-child {
    padding-right: 0;
    padding-left: 2rem;
  }
  .align-height{
    padding-top:40.25% ;
  }
  .bold{
    font-weight: 700;
  }
  .secondary-btn-green {
    border: 2px solid var(--Persian-Green);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--Persian-Green);
  }
  .secondary-btn-green:hover {
    background: var(--Persian-Green);
    color: var(--White);
    border: 2px solid var(--Persian-Green);
  }
  .secondary-btn-green:focus {
    background: var(--highreadability);
    color: var(--White);
    border: 2px solid var(--highreadability);
  }
  .secondary-btn-green:active {
    background: var(--highreadability);
    color: var(--White);
    border: 2px solid var(--highreadability);
  }
  .related-article {
    background: var(--white);
    border-radius: 16px;
  }
  .related-img img{
    width: 100%;
    height: 100%;
    max-height: 15rem;
    object-fit: contain;
  }
  .related-img img{
  border-radius: 16px 0 0 16px;
  }

  .trending-card {
    border-radius: 16px;
     display: flex;
     flex-direction: column;
     height: 100%;
  }
.trending-card-img-display{
  border-radius: 16px  16px 0 0;
}
@media (max-width: 992px){
  .banner-image{
    border-radius: 0px;
  }
}
  .insta-footer{
    background: rgba(26, 26, 26, 0.88);
    width:100%;
    border-radius: 0 0 16px 16px;
    height: 5rem;
  }
  @media (max-width: 768px){
 
      .align-height{
        padding-top:55.25% ;
      }
      .h-52{
        min-height: 10.5rem;
        object-fit: cover;
        height: auto;
      }
}
@media (min-width:658px) and (max-width: 767px){

  .mobile-blogs-banner-article {
    position: relative;
    height: 40vh; 
    width: 100%;
  }}
@media (min-width:768px) and (max-width: 993px){

.mobile-blogs-banner-article {
  position: relative;
  height: 50vh; 
  width: 100%;
}
 }
@media  (max-width: 365px){

  .mobile-blogs-banner-article {
    position: relative;
    height: 25vh; 
    width: 100%;
  }}

  @media  (min-width: 1441px){
  .related-img img{
    width: 100%;
    height: 100%;
    max-height: 15rem;
    object-fit: fill;
  }}
  @media (min-width:  418px) and (max-width:  478px){

    .related-mobile-card {
    height: 21rem;
    }
     }
