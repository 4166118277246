.flex-grow-1 {
    flex-grow: 1;
  }
  
  .img-fluid {
    height: 24rem;  
    width: 100%;
    object-fit: cover;
  }
  
  .pt-12 {
    padding-top: 3rem;  
  }
  
  .pt-md-8 {
    padding-top: 2rem; 
  }
  
  .rounded-corners {
    border-radius: 16px !important;
}
.rounded-image-top {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

  .mobile-blogs-banner {
    position: relative;
    height: 70vh; 
    width: 100%;
}

.img-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.container-custom-blogs{
  max-width: 72rem;  
}
 
@media screen and (min-width:1200px) and (max-width:1440px){
  .container-custom-blogs{
    max-width: 65rem;  
  }
}
@media screen and (min-width:993px) and (max-width:1199px){
  .container-custom-blogs{
    max-width: 58rem;  
  }
}
@media screen and (min-width:768px) and (max-width:992px) {
  .container-custom-blogs{
    max-width:43rem;  
  }
}
@media screen and (min-width:576px) and (max-width:767px) {
  .container-custom-blogs{
    max-width:33rem;  
  }
}
@media screen and (min-width:576px) and (max-width:767px) {
  .container-custom-blogs {
   padding: 0 3px 0 3px; 
  }
}