.cookie-banner {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}
.cookie-banner text {
  font-size: 1rem;
  font-weight: 800;
}
.cookie-banner button {
  border: none;
}
.close-button {
  transform: translateY(-30px);
}
.cookie-banner {
  background: #ffffff;
  box-shadow: 0px 8px 18px 4px rgba(26, 26, 26, 0.16);
  border-radius: 16px 16px 0px 0px;
}

.cookie-banner a {
  color: var(--Persian-Green);
}
.close-button {
  background-color: #ffffff;

  font-size: 1.5rem;
}
.close-button i {
  color: var(--Persian-Green);
  border: none;
}

.cookie-banner .accept-btn {
  background: var(--Persian-Green);
  color: var(--White);
  border-radius: 4px;
  border: none;
}
.cookie-banner .accept-btn:hover {
  background: var(--button-gradient);
}
.cookie-banner {
  animation: fadeIn 0.5s;
}

.cookie-image img {
  transform: scale(1);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .cookie-banner {
    background: #ffffff;
    margin-left: 3vw;
  }

  .close-button {
    font-size: 1rem;
  }
  .cookie-banner {
    position: fixed;
    bottom: 4.5rem;
    z-index: 1000;
  }
  .cookie-image {
    transform: translateX(-28px);
  }
  .close-button {
    transform: translateY(-30px);
  }
  .cookie-image img {
    transform: scale(0.7);
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  .close-button {
    font-size: 1.25rem;
  }

  .cookie-image img {
    transform: scale(0.7);
  }
  .cookie-banner {
    position: fixed;
    bottom: 4.5rem;
    z-index: 1000;
  }
  .close-button {
    transform: translateY(-30px);
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .close-button {
    font-size: 1.25rem;
  }

  .cookie-image img {
    transform: scale(0.7);
  }
  .cookie-banner {
    position: fixed;

    z-index: 1000;
  }
  .cookie-image {
    transform: translateX(-15px);
  }
  .close-button {
    transform: translateY(-15px);
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cookie-image img {
    transform: scale(0.9);
  }
  .cookie-banner {
    margin-left: 4vw;
  }
  .close-button {
    transform: translateY(-25px);
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cookie-image img {
    transform: scale(1.2);
    transform: translateX(12px);
  }
  .cookie-banner {
    margin-left: 4vw;
  }
  .close-button {
    transform: translateY(-25px);
  }
  .cookie-image {
    transform: translateX(-28px);
  }
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .close-button {
    transform: translateY(-5px);
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .cookie-image img {
    height: 100%;
    width: 100%;
  }
  .cookie-banner {
    margin-left: 3vw;
  }

  .close-button {
    transform: translateY(-5px);
  }
}
@media (min-width: 1400px) {
  .cookie-banner {
    margin-left: 4vw;
  }
}
@media (min-width: 576px) {
  .cookie-banner {
    margin-left: 4vw;
  }
}
