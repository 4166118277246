.footer {
  background-image: url("./Footer-bg.svg");
  max-width: 100%;
  min-height: 45vh;
  background-size: cover;
  position: relative;
}
  .powered a:hover{
  color:  #ffffff;
}
  .powered a {
  color: var(--Light-Silver);
}
footer p {
  color: var(--Light-Silver);
}
footer p:hover {
  color: var(--White);
}
footer a {
  text-decoration: none;
}

footer h5 {
  color: var(--Persian-Green);
}
.footer-logo img {
  transform: scale(1.6);
}
.reachus li {
  cursor: pointer;
}
.footer .social-icons {
  font-size: 30px;
  color: var(--Light-Silver);
}
.footerservices button {
  background: transparent;
}
.footerservices p {
  color: var(--Light-Silver);
}
.floatingbutton {
  position: fixed;
  bottom: 0;
  height: 4.8rem;
  width: 100%;
  filter: drop-shadow(0px -4px 9px rgba(26, 26, 26, 0.25));
  z-index: 9999;
  font-weight: 600;
}

.phone-btn,
.phone-btn:active,
.phone-btn:focus,
.phone-btn:hover {
  color: var(--Teal-Green);
  background: var(--Baby-Powder);
  outline: none;
}
.whatsapp-btn,
.whatsapp-btn:active,
.whatsapp-btn:focus,
.whatsapp-btn:hover {
  background: var(--Baby-Powder);
  color: var(--Teal-Green);
  outline: none;
}

.book-btn,
.book-btn:active,
.book-btn:focus,
.book-btn:hover {
  background: var(--Baby-Powder);
  color: var(--Teal-Green);
  outline: none;
}
.footer-social-icons-mobile {
  font-size: 2rem;
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .floatingbutton {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .floatingbutton {
    position: fixed;
    bottom: 0;
    background: var(--Baby-Powder);
    z-index: 9999;
    width: 100%;
  }

  .hidemeforpc {
    display: block;
  }
  .hidemeformobile {
    display: none;
  }
  .footer {
    padding-bottom: 4.8rem;
  }
}
@media (min-width: 1920px) {
  .footer {
    width: 100%;
    min-height: 45vh;
    position: relative;
  }
}
