.download-button-nav {
  background: var(--Teal-Green);
  color: var(--White);

  border-radius: 6px;
}
.call-download-mobile .download-button-nav {
  border: 1px solid var(--Baby-Powder);
}
.showmefortablet-lg .download-button-nav {
  border: 1px solid var(--Baby-Powder);
}
.download-button-nav i {
  color: var(--White);
}
.download-button-nav:hover {
  background: var(--Website-gradient);
}
.download-button-nav:focus:active {
  border: 1px solid var(--highreadability);
  color: var(--highreadability);
  background: transparent;
}
.download-button-nav:focus:active i {
  color: var(--highreadability);
}
.material-symbols-rounded {
  font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 48;
  color: var(--Eerie-Black);
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.callbutton {
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.callbutton p {
  margin: auto 0;
}
.callbutton span {
  margin: auto 0;
}
