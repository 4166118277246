section .thankyou {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
}

.thankyou h1 {
  color: var(--Eerie-Black);
}
.thankyou text {
  color: var(--Teal-Green);
}
.thankyou p {
  color: var(--Granite-Gray);
}
.back-to-home {
  border: 1px solid var(--Teal-Green);
  color: var(--Teal-Green);
  background: transparent;
  border-radius: 8px;
  display: inline-block;
}
button {
  display: inline-block;
}
.back-to-home:hover {
  background: rgba(17, 159, 147, 0.16);
  border: 1px solid var(--Teal-Green);
}
.back-to-home:active:focus {
  border: 1px solid var(--highreadability);
  color: var(--highreadability);
  background: transparent;
}
.back-to-home:default {
  border: 1px solid var(--Teal-Green);
  color: var(--Teal-Green);
  background: transparent;
  border-radius: 8px;
}
.thankyou .social-icons {
  display: flex;
  justify-content: start;
}
@media (min-width: 460px) and (max-width: 992px) {
  .thankyou img {
    transform: scale(0.8);
  }
  .thankyou .social-icons {
    display: flex;
    justify-content: center;
  }
  .back-to-home {
    width: 100%;
  }
}
@media (min-width: 460px) and (max-width: 768px) {
  .thankyou img {
    margin-left: -10vw;
  }
  .thankyou .social-icons {
    display: flex;
    justify-content: center;
  }
  .back-to-home {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 460px) {
  .thankyou img {
    transform: scale(0.7);
    margin-left: -25vw;
  }
  .thankyou .social-icons {
    display: flex;
    justify-content: center;
  }
  .back-to-home {
    width: 100%;
  }
}
@media (min-width: 1921px) {
  .thankyou {
    width: 100%;
    min-height: 55vh;
    position: relative;
  }
}
